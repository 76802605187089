import React from 'react'
import PageWrapper from '../../components/template/PageWrapper/PageWrapper'
import PageTitle from '../../components/template/PageTitle/PageTitle'

function Home() {
  return (


    <PageWrapper>
      <PageTitle title="صفحه اصلی"/>

      <div className="row">
        <div className="col-lg-12">
          <div className="card custom-card">
            <div className="card-body">
              نوشتن برخی متن ها در اینجا ....
            </div>
          </div>
        </div>
      </div>
    </PageWrapper>






  )
}

export default Home