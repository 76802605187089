import React, { useCallback, useEffect, useState } from 'react'
import Wrapper from '../../../hoc/Wrapper/Wrapper'
import SubMenuItem from "./SubMenuItem";

function MenuItem(props) {
    const [navItemClass, setNavItemClass] = useState("nav-item")

        useEffect(useCallback(
            ()=>{

                console.log("BBBB",props.item.url)
                    if(props.item.url==props.active){
                        
                        setNavItemClass(navItemClass+ " active")
                        console.log("AAA",props.item.url)
                    }else{
                        setNavItemClass(navItemClass.replace("active",""))
                    }
    
            },[props.active]
        ),[props.active])

   
    const dropDownMenuClicked = (event) => {
        event.preventDefault();
        if (navItemClass === "nav-item")
            setNavItemClass("nav-item show")
        else
            setNavItemClass("nav-item")
    }

    let menuTag = null;

    if (props.item.isHeader) {
        menuTag = <li className="nav-header"><span className="nav-label">{props.item.title}</span></li>
    } else {
        if (!props.item.childs || props.item.childs.length == 0) {
            menuTag = <Wrapper>
                <li className={navItemClass}>
                    <a className="nav-link" href="" onClick={(event) => props.clicked(event, props.item)}><span className="shape1"></span><span className="shape2"></span><i className={props.item.icon + " ml-2"}></i><span className="sidemenu-label">{props.item.title}</span></a>
                </li>
            </Wrapper>
        } else {

            menuTag = <li className={navItemClass}>
                <a className="nav-link with-sub" href="#" onClick={(event) => dropDownMenuClicked(event)}><span className="shape1"></span><span className="shape2"></span><i className={props.item.icon + " ml-2"}></i><span className="sidemenu-label">{props.item.title}</span><i className="angle fe fe-chevron-left"></i></a>
                <ul className="nav-sub">
                    {
                        props.item.childs.map((item) => {
                            return <SubMenuItem item={item} clicked={props.clicked} active={props.active}/>
                        }
                        )
                    }
                </ul>
            </li>
        }
    }


    return (
        menuTag
    )
}

export default MenuItem