import axios from "axios";
import * as appConsts from '../store/consts'
class API {
    constructor(parameters) {
        
    }

    sendPostWithoutToken(){
        
    }
    sendPatchWithToken(url,data,funcSuccess,funcError=null){

      const token= localStorage.getItem("token")
      console.clear();
      console.log(token)
      if(!token)
        return false;
      let config = {
        method: 'patch',
        maxBodyLength: Infinity,
        url: `${appConsts.api_url}${url}`,
        headers: { 
          'Content-Type': 'application/json', 
          'Authorization': `Bearer ${token}`
        },
        data : data
      };
      
      axios.request(config)
      .then((response) => {
        funcSuccess(response.data);
      })
      .catch((error) => {
        if(funcError!=null){
          funcError(error);
      }
      });
    }
    sendPostWithToken(url,data,funcSuccess,funcError=null){

      const token= localStorage.getItem("token")
      console.clear();
      console.log(token)
      if(!token)
        return false;
      let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: `${appConsts.api_url}${url}`,
        headers: { 
          'Content-Type': 'application/json', 
          'Authorization': `Bearer ${token}`
        },
        data : data
      };
      
      axios.request(config)
      .then((response) => {
        funcSuccess(response.data);
      })
      .catch((error) => {
        if(funcError!=null){
          funcError(error);
      }
      });
    }
   async sendGETWithToken(url,funcSuccess,funcError=null){
        const token= localStorage.getItem("token")
        console.clear();
        console.log(token)
        if(!token)
          return false;
        axios.get(`${appConsts.api_url}${url}`,{
            headers: {
              Authorization: `Bearer ${token}`
             
            }
          })
              .then(response => {
              
              
                funcSuccess(response.data);
                  
              
              
              
              })
              .catch(error => {
            
                if(funcError!=null){
                    funcError(error);
                }
              });
      
    
    
    
            }
}

export default API