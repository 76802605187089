import React, { useEffect, useState } from 'react'
import PageWrapper from '../../../components/template/PageWrapper/PageWrapper'
import PageTitle from '../../../components/template/PageTitle/PageTitle'
import PageCard from '../../../controls/PageCard/PageCard'
import Row from '../../../controls/Row/Row'
import Col from '../../../controls/Col/Col'


import './HomeBanner.css'
function HomeBanner() {
    return (
        <PageWrapper>
            <PageTitle title="بنر صفحه اصلی" />
            <Row>
                <Col size="lg-12">

                    <PageCard>

                        <div className='banner_section'>
                            <div className="container">
                                <div className="banner_text">
                                    <div className="ban_inner_text aos-init aos-animate" data-aos="fade-up" data-aos-duration="1500">
                                        <span>Powerful, easier and faster</span><h1>Grow your software <br /> faster &amp; easier with Dashbord</h1>
                                        <p>The World's simplest and fast platform that brings everything together</p></div>
                                    <form action="submit" data-aos="fade-up" data-aos-duration="1500" className="aos-init aos-animate">
                                        <div className="form-group">
                                            <input type="text" className="form-control" placeholder="Enter your email" />
                                            <button className="btn btn_main">TRY FOR FREE <i className="icofont-arrow-right"></i></button>
                                        </div><span>Already using software? <a href="#">Sign in</a></span></form>
                                </div>
                                <div className="banner_images aos-init aos-animate" data-aos="fade-up" data-aos-duration="1500">
                                    <img src="./assets/banners/banner_01.png" alt="image" />
                                    <div className="sub_images"><img className="moving_animation" src="./assets/banners/banner_02.png" alt="image" />
                                        <img className="moving_animation" src="./assets/banners/banner_03.png" alt="image" />
                                        <img className="moving_animation" src="./assets/banners/banner_04.png" alt="image" /></div></div></div>

                        </div>


                    </PageCard>
                </Col>
            </Row>
        </PageWrapper>
    )
}

export default HomeBanner