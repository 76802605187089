import React from 'react'

function LoginLoader(props) {
    let classNames="d-none"
    if(props.isShow){
        classNames=""
    }
    return (
    <div className={classNames} id="global-loader">
    <img src="./assets/img/loader.svg" className="loader-img" alt="Loader"/>
</div>
  )
}

export default LoginLoader