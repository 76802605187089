import React from 'react'

function LoginRightSide() {
	return (
		<div className="col-lg-6 col-xl-5 d-none d-lg-block text-center bg-primary details">
			<div className="mt-5 pt-4 p-2 pos-absolute">
				<img src="assets/img/brand/logo-light.png" className="header-brand-img mb-4" alt="logo" />
				<div className="clearfix"></div>
				<img src="assets/img/svgs/user.svg" className="ht-100 mb-0" alt="user" />
				<h5 className="mt-4 text-white">به حساب خود وارد شوید</h5>
				<span className="tx-white-6 tx-13 mb-5 mt-xl-0">از قرار دادن اطلاعات خود به دیگران خودداری کنید</span>
			</div>
		</div>
	)
}

export default LoginRightSide