import React, { useEffect, useState } from 'react'
import Table from '../../controls/Table/Table'
import THead from '../../controls/Table/THead'
import API from '../../modules/api'
import Utils from '../../modules/utils'

function UserProducts(props) {
    const [productsState, setProductsState] = useState([])
    useEffect(() => {

        (new API()).sendGETWithToken(`/api/users/products/${props.item.id}`, (result) => {
            setProductsState(result);
        })

    }, [props.item])
    return (
        <Table>
            <THead>
                <th>نام محصول</th>
                <th>تاریخ انقضا</th>
                <th>پکیج</th>

            </THead>
            <tbody>
                {

                    productsState.map((item) => {
                        return <tr>
                            <td>{item.name}</td>
                            <td>{(new Utils()).toPersianDateTime( new Date(item.expire_account))}</td>
                            <td>{item.packagename}</td>
                        </tr>

                    })
                }
            </tbody>
        </Table>
    )
}

export default UserProducts