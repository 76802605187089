import React from 'react'
import Wrapper from '../../../hoc/Wrapper/Wrapper'

function MenuItem(props) {
    let menuTag =  <li className="nav-sub-item">
    <a className="nav-sub-link" href="" onClick={(event) => props.clicked(event, props.item)}>{props.item.title}</a>
</li>;
   

  return (
   menuTag
  )
}

export default MenuItem