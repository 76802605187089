import React, { useEffect, useState } from 'react'
import MenuItem from './MenuItem'
import { useNavigate } from "react-router-dom";
function SideBar() {
    const navigate = useNavigate();
    const [menuActiveState, setMenuActiveState] = useState(window.location.pathname)
    const [menuState, setMenuState] = useState([
        { title: "مدیر سیستم", url: "-", icon: "", isHeader: true, childs: [] },
        { title: "محصولات", url: "/products", icon: "ion ion-help", isHeader: false, childs: [] },
        {
            title: "سایت", url: "/site", icon: "ion ion-navigate", isHeader: false, childs: [
                { title: "بنر صفحه اصلی", url: "/home-banner", icon: "fa fa-image", isHeader: false,parent:"/site", childs: [] },
                { title: "اسلاید", url: "/langs", icon: "fa fa-lang", isHeader: false,parent:"/site", childs: [] },
                { title: "شبکه های اجتماعی", url: "/users", icon: "fa fa-users", isHeader: false,parent:"/site", childs: [] },
            ]
        },
        {
            title: "سیستم", url: "/system", icon: "ion ion-navigate", isHeader: false, childs: [
                { title: "زبانها", url: "/langs", icon: "fa fa-lang", isHeader: false,parent:"/system", childs: [] },
                { title: "کاربران", url: "/users", icon: "fa fa-users", isHeader: false,parent:"/system", childs: [] },
            ]
        },

        //{title:"",url:"",icon:"",isHeader:false,childs:[]},
    ])
    useEffect(()=>{

    

        setMenuActiveState(window.location.pathname)

    },[])

    const MenuItemClicked=(event,item)=>{
        event.preventDefault();
        if(item.parent)
        setMenuActiveState(item.parent)
    else
        setMenuActiveState(item.url)
        navigate(item.url)
    }
    return (
        <div class="main-sidebar main-sidebar-sticky side-menu">
            <div class="sidemenu-logo">
                <a class="main-logo" href="index.html">
                    <img src="assets/img/brand/logo-light.png" class="header-brand-img desktop-logo" alt="لوگو" />
                    <img src="assets/img/brand/icon-light.png" class="header-brand-img icon-logo" alt="لوگو" />
                    <img src="assets/img/brand/logo.png" class="header-brand-img desktop-logo theme-logo" alt="لوگو" />
                    <img src="assets/img/brand/icon.png" class="header-brand-img icon-logo theme-logo" alt="لوگو" />
                </a>
            </div>
            <div class="main-sidebar-body">
                <ul class="nav">
                    {
                        menuState.map((item) => {
                            return <MenuItem item={item} clicked={MenuItemClicked} active={menuActiveState}/>
                        }
                        )

                    }

                </ul>
            </div>
        </div>
    )
}

export default SideBar