import React from 'react'

function TextBox({title,name,value,changed,type="text"}) {

   

  return (
    <div class="form-group">
    <label class="">{title}</label>
    <input class="form-control" required="" name={name} value={value} onChange={changed} type={type}/>
</div>
  )
}

export default TextBox