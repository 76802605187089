import React, { useEffect, useState } from 'react'
import TextBox from '../../controls/TextBox/TextBox';
import Select2 from '../../controls/Select2/Select2';
import { Directions } from '../../store/consts';

function AddLang(props) {
    const [itemUseState, setItemUseState] = useState({})

    useEffect(() => {
        if (props.item) {
            setItemUseState(props.item);
        }
    }, [props.item]);

    // Handle input changes
    const handleChange = (e) => {
        const { name, value } = e.target;


       // alert(name)
       // alert(value)
        setItemUseState({
            ...itemUseState,
            [name]: value,
        });

    };

    useEffect(() => {

        console.log(itemUseState)
        props.changed(itemUseState)
    }, [itemUseState])
    return (
        <div className="row row-sm">
            <div className="col-md-12 col-lg-12 col-xl-12">
                <div className="">
                    <TextBox title="نام" name="name" value={itemUseState.name} changed={handleChange} />
                    <TextBox title="عنوان" name="title" value={itemUseState.title} changed={handleChange} />
                    <Select2 options={Directions} label="جهت" onChange={handleChange} defaultValue={itemUseState.dir} name="dir"/>



                  

                    


                </div>
            </div>
        </div>
    )
}

export default AddLang