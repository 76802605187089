import React from 'react'

function AlertError(props) {

    let classNames="alert alert-danger mg-b-0 d-none"
    if(props.isShow){
        classNames="alert alert-danger mg-b-0"
    }
    return (
        <div className={classNames} role="alert">
            <button aria-label="بستن" className="close" data-dismiss="alert" type="button">
                <span aria-hidden="true">×</span>
            </button>
            <strong>{props.title?props.title:"اخطار!"} </strong>  {props.message}        </div>
    )
}

export default AlertError