import React from 'react'

function Footer() {
  return (
    <div className="main-footer text-center">
		<div className="container">
			<div className="row row-sm">
				<div className="col-md-12">
					<span>کپی رایت © 1399  . طراحی شده توسط <a href="#">Themefix</a> کلیه حقوق محفوظ است.</span>
				</div>
			</div>
		</div>
	</div>
  )
}

export default Footer