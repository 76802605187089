import React, { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router';
import API from '../../modules/api'
import PageWrapper from '../../components/template/PageWrapper/PageWrapper'
import PageTitle from '../../components/template/PageTitle/PageTitle'
import PageCard from '../../controls/PageCard/PageCard'
import Row from '../../controls/Row/Row'
import Col from '../../controls/Col/Col'
import Table from '../../controls/Table/Table'
import THead from '../../controls/Table/THead'
import Modal from '../../controls/Modal/Modal'
import PackagesList from '../../components/Packages/PackagesList'
import EditPackage from '../../components/Packages/EditPackage'
import AddPackage from '../../components/Packages/AddPackage'
import DetailList from '../../components/Packages/DetailList'
import { useNavigate } from "react-router-dom";
function Packages(props) {
    const { id } = useParams();
    const [ProductState, setProductState] = useState([])
    const [PackagesState, setPackagesState] = useState([])
    const [showModal, setShowModal] = useState(false);
    const [childModal, setchildModal] = useState(null);
    const [titleModal, settitleModal] = useState("");
    const [selectedPackage, setSelectedPackage] = useState(null);
    const [selectedAction, setSelectedAction] = useState(null);
    const navigate = useNavigate();
    
    useEffect(() => {
  
      (new API()).sendGETWithToken(`/api/packages/product/${id}`, (result) => {
        setProductState(result.product)
        setPackagesState(result.packages);
      })
  
    }, [])

  
    const GoBackClicked = (event) => {   
         navigate(`/products`)
    }
    const AddPackageClicked = (event) => {
      event.preventDefault();
      setSelectedPackage(null)
      settitleModal("افزودن پکیج")
      setSelectedPackage({})
      setSelectedAction("add")
      setchildModal(<AddPackage item={null} changed={onSetPackageUpdate} />)
      setShowModal(true)
    }
    const onSaveModal = (event) => {
      event.preventDefault();
  
      switch (selectedAction) {
        case "edit":
          if (selectedPackage) {
            (new API()).sendPatchWithToken(`/api/packages/${selectedPackage.id}`, selectedPackage, (result) => {
              console.log(result);
              setSelectedAction(null)
              setSelectedPackage(null)
              setShowModal(false)
                console.clear()
                setPackagesState(result.packages);
              
            })
          }
          break;
          case "add":
            if (selectedPackage) {
                selectedPackage.product_id=id;
              (new API()).sendPostWithToken(`/api/Packages`, selectedPackage, (result) => {
                console.log(result);
                setSelectedAction(null)
                setSelectedPackage(null)
                setShowModal(false)
                  console.clear()
                  setPackagesState(result.packages);
                
              })
            }
            break;
    
        default:
          break;
      }
  
  
  
  
    }
  
    const closePackageClicked = (event) => {
      event.preventDefault();
      setSelectedPackage(null)
      setShowModal(false)
    }
    const onSetPackageUpdate = (item) => {
      //event.preventDefault();
      console.log("VVV", item)
      setSelectedPackage(item)
    }
    const PackageItemClicked = (event, item, action) => {
      event.preventDefault();
      setSelectedPackage(null)
      switch (action) {
        case "edit":
          settitleModal("ویرایش پکیج")
         
          setSelectedPackage(item)
          setSelectedAction(action)
          setchildModal(<EditPackage item={item} changed={onSetPackageUpdate} />)
          setShowModal(true)
          break;
        case "details":
          settitleModal("اطلاعات")
         
          setSelectedPackage(item)
          setSelectedAction(action)
          setchildModal(<DetailList item={item} changed={onSetPackageUpdate} />)
          setShowModal(true)
          break;
       
        default:
          break;
      }
    }
  
    return (
      <PageWrapper>
        <PageTitle title="پکیجات" />
        <Row>
          <Col size="lg-12">
  
            <PageCard>
              <div className="btn btn-list mb-2">
                <button className="btn ripple btn-secondary" onClick={GoBackClicked}>
                  <i className="fe fe-arrow-right mx-1 mt-3"></i>
                  بازگشت </button>
                <button className="btn ripple btn-primary" onClick={AddPackageClicked}>
                  <i className="fe fe-plus-circle mx-1 mt-3"></i>
                  پکیج جدید </button>
              </div>
              <Table>
                <THead>
                  <th>#</th>
                  <th>عنوان</th>
                  <th>مبلغ ریالی</th>
                  <th>مبلغ دلاری</th>
                  <th>تعداد روز</th>
                  <th>نوع</th>
                  <th>فعال</th>
                
                </THead>
                <tbody>
                  {
  
                    PackagesState.map((item) => {
                      return <PackagesList item={item} click={PackageItemClicked} />
  
                    })
  
                  }
                </tbody>
              </Table>
              <Modal isShow={showModal} close={closePackageClicked} title={titleModal} saveClick={(event) => onSaveModal(event)}>
                {childModal}
              </Modal>
            </PageCard>
          </Col>
        </Row>
  
      </PageWrapper>
  
  )
}

export default Packages