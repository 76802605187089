import React,{useState,useEffect,useCallback} from 'react'

import { connect,useDispatch } from 'react-redux'

import {addChangeLogin} from './store/projectActions'
import './App.css';
import Login from './pages/Login/Login'
import API from "./modules/api";
import Layout from './layout/Layout';
function App(props) {
  const [childState,setLchildState]=useState(false)
  const dispatch = useDispatch()


  useEffect(()=>{
    props.onLoad()
    const token= localStorage.getItem("token")
    if(!token)
      return;

    (new API()).sendGETWithToken("/",()=>{dispatch(addChangeLogin(true))},(err)=>{dispatch(addChangeLogin(false))})
    
    console.log("MMM",props.is_login)
    
   
},[])
useEffect(()=>{
    console.log("NNNN",props.is_login)
    if(props.is_login){
        setLchildState(<Layout/>)
    }else{
        setLchildState(<Login/>)
    }
   // props.onLoad()
},[props.is_login])
  return (
    childState
  );
}

const mapStateToProps = (state) => {
  return {
    ...state,
      is_login: state.is_login,
   
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onLoad: () => dispatch({ type: 'CHANGELOGIN',value:false }),
   
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(App)
