import React from 'react'

function TextBox({title,name,value,changed}) {

   

  return (
    <div class="form-group">
    <label class="">{title}</label>
    <textarea class="form-control" required="" name={name} value={value} onChange={changed}>
    {value}
    </textarea>
    
</div>
  )
}

export default TextBox