import React, { useState, useEffect } from 'react'
import { connect,useDispatch } from 'react-redux'

import {addChangeLogin} from '../../store/projectActions'

import Wrapper from '../../hoc/Wrapper/Wrapper'
import LoginLoader from "../../components/Login/Loader/Loader";
import LoginRightSide from '../../components/Login/LoginRightSide/LoginRightSide';

import * as appConsts from '../../store/consts'
import axios from "axios";
import AlertError from '../../controls/AlertError/AlertError';
function Login(props) {
	const dispatch = useDispatch()

	const [loadingShow, setLoadingShow] = useState(false)
	const [alertShow, setAlertShow] = useState(false)
	const [alertMessage, setAlertMessage] = useState("")

	const [username, setUsername] = useState('');
	const [password, setPassword] = useState('');

	const handleSubmit = (event) => {
		event.preventDefault();
		setAlertShow(false)
	
		setLoadingShow(true);
		axios.post(`${appConsts.api_url}/api/login/admin`,{username:username,password:password})
		.then(response => {
			console.log(response.data)
		if(response.data.code==200){
			dispatch(addChangeLogin(true))
			
			localStorage.setItem("token",response.data.token)
		}
		  setLoadingShow(false)
		})
		.catch(error => {
			console.log(error.response.status)
			setAlertMessage("نام کاربری یا کلمه عبور اشتباه است")
			setAlertShow(true)
		  setLoadingShow(false);
		});


		// Reset form fields after submission
	//	setUsername('');
	//	setPassword('');
	}
	return (
		<Wrapper>


			<LoginLoader isShow={loadingShow} />

			<div className="page main-signin-wrapper">


				<div className="row signpages text-center">
					<div className="col-md-12">
						<div className="card">
							<div className="row row-sm">
								<LoginRightSide />
								<div className="col-lg-6 col-xl-7 col-xs-12 col-sm-12 login_form ">
									<div className="container-fluid">
										<div className="row row-sm">
											<div className="card-body mt-2 mb-2">
												<img src="./assets/img/brand/logo.png" className=" d-lg-none header-brand-img text-left float-left mb-4" alt="logo" />
												<div className="clearfix"></div>
												<AlertError isShow={alertShow} message={alertMessage}/>
												<form onSubmit={handleSubmit}>
													<h5 className="text-right mb-2">به حساب خود وارد شوید</h5>
													<div className="form-group text-right">
														<label>نام کاربری</label>
														<input className="form-control" placeholder="نام کاربری خود را وارد کنید" type="text"
														
														value={username}
														onChange={(e) => setUsername(e.target.value)}
														/>
													</div>
													<div className="form-group text-right">
														<label>کلمه عبور</label>
														<input className="form-control" placeholder="رمز ورود خود را وارد کنید" type="password" 
														
														value={password}
														onChange={(e) => setPassword(e.target.value)}
														/>
													</div>
													<button className="btn ripple btn-main-primary btn-block">ورود</button>
												</form>
												<div className="text-right mt-5 ml-0">
													<div className="mb-1"><a href="#">رمز عبور خود را فراموش کرده اید؟</a></div>
													
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>


			</div>
		</Wrapper>
	)
}

export default Login