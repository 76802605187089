import React, { useEffect, useState } from 'react'
import TextBox from '../../controls/TextBox/TextBox';
import TextArr from '../../controls/TextArr/TextArr';
import Select2 from '../../controls/Select2/Select2';
import Utils from "../../modules/utils";
function AddWord(props) {
    const [itemUseState, setItemUseState] = useState({})

    useEffect(() => {
        if (props.item) {
            setItemUseState(props.item);
        }
    }, [props.item,setItemUseState]);

    // Handle input changes
    const handleChange = (e) => {
        const { name, value } = e.target;


       // alert(name)
       // alert(value)
        setItemUseState({
            ...itemUseState,
            [name]: value,
        });

    };

    const  translateClick=(event)=>{
        event.preventDefault();
      
       console.log(itemUseState)
        if(itemUseState!=null && itemUseState.word!=""){
            (new Utils()).translateWord(props.langState.name,itemUseState.word,function (result) {
                setItemUseState({
                    ...itemUseState,
                    translate: result,
                });
            })

         
          
          
        }
      }


    useEffect(() => {

        console.log(itemUseState)
        props.changed(itemUseState)
    }, [itemUseState])
    return (
        <div className="row row-sm">
            <div className="col-md-12 col-lg-12 col-xl-12">
                <div className="">
                <button className="btn ripple btn-primary" type="button" onClick={(event)=>translateClick(event)}><i className='fa fa-bullhorn'></i> <span>ترجمه</span> </button>

                    <TextArr title="کلمه" name="word" value={itemUseState.word} changed={handleChange} />
                    <TextArr title="ترجمه" name="translate" value={itemUseState.translate} changed={handleChange} />
                   


                  

                    


                </div>
            </div>
        </div>
    )
}

export default AddWord