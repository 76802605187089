import React from 'react'

function Table(props) {
    return (
        <table id={props.id} className="table text-nowrap text-md-nowrap table-bordered mg-b-0">
            {props.children}

        </table>
    )
}

export default Table