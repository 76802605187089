import React, { useEffect, useRef } from 'react';
import $ from 'jquery';
import 'select2/dist/css/select2.min.css';
import 'select2';
import './Select2.css'
function Select2({ options, onChange, defaultValue, label, name }) {
    const selectRef = useRef(null);

    useEffect(() => {

        console.log("TTTTZ",options)
        // Initialize Select2 on the referenced select element
        $(selectRef.current).select2({
            data: options,
        });

        // Event listener for handling change in selection
        $(selectRef.current).on('change', function (e) {
            onChange(e);
        });

        // Cleanup function to destroy Select2 instance
        return () => {
            $(selectRef.current).select2('destroy');
        };
    }, [options, onChange]);

    return (
        <div class="form-group">
            <label class="">{label && <label>{label}</label>}</label>
            <select className='form-control select select2' name={name} ref={selectRef} value={defaultValue} defaultValue={defaultValue} style={{ width: '100%' }}>
                {options.map((option) => (
                    <option key={option.value} value={option.value}  {...defaultValue==option.value?"selected":""}>
                        {option.label}
                    </option>
                ))}
            </select>
        </div>
    );
};

export default Select2