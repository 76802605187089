import React from 'react'

function PageTitle(props) {
  return (
    <div className="page-header">
    <div>
      <h2 className="main-content-title tx-24 mg-b-5">{props.title}</h2>
      <ol className="breadcrumb">
        <li className="breadcrumb-item"><a href="#">صفحات</a></li>
        <li className="breadcrumb-item active" aria-current="page">{props.title}</li>
      </ol>
    </div>
    <div className="d-flex">
      <div className="justify-content-center d-none">
        <button type="button" className="btn btn-white btn-icon-text my-2 ml-2">
          وارد كردن<i className="fe fe-download mr-2"></i>
        </button>
        <button type="button" className="btn btn-white btn-icon-text my-2 ml-2">
          فیلتر<i className="fe fe-filter ml-2"></i>
        </button>
        <button type="button" className="btn btn-primary my-2 btn-icon-text">
          گزارش را بارگیری کنید<i className="fe fe-download-cloud mr-2"></i>
        </button>
      </div>
    </div>
  </div>
  )
}

export default PageTitle