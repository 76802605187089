import * as actionTypes from './actions'


import InistialState from './InistialState'
  const reducer = (state = InistialState, action) => {
    switch (action.type) {
      case actionTypes.CHANGEVIEW:
        return {
          ...state,
          path: action.value,
        }
      case actionTypes.CHANGELANG:
        return {
          ...state,
          lang: action.value,
        }
      case actionTypes.CHANGELOGIN:
        console.log("ZZZZ",action.value)
        return {
          
          ...state,
          is_login: action.value,
        }

      default:
        return state
    }
  }
  
  export default reducer
  