import React from 'react'

function PageCard(props) {
    return (
        <div className="card custom-card">
            <div className="card-body">
                {props.children}
            </div>
        </div>
    )
}

export default PageCard