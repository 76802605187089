export const addChangeView = (mpath) =>  (dispatch, getState) => {
  
    dispatch({
      type: 'CHANGEVIEW',
      value: mpath
    })
}
export const addChangeLang = (mlang) =>  (dispatch, getState) => {
  
    dispatch({
      type: 'CHANGELANG',
      value: mlang
    })
}
export const addChangeLogin = (is_login) =>  (dispatch, getState) => {
  console.log("TTT",is_login)
    dispatch({
      type: 'CHANGELOGIN',
      value: is_login
    })
}

