import React, { useCallback, useEffect, useState } from 'react'
import API from '../../modules/api'
import PageWrapper from '../../components/template/PageWrapper/PageWrapper'
import PageTitle from '../../components/template/PageTitle/PageTitle'
import PageCard from '../../controls/PageCard/PageCard'
import Row from '../../controls/Row/Row'
import Col from '../../controls/Col/Col'
import Table from '../../controls/Table/Table'
import THead from '../../controls/Table/THead'
import LangList from '../../components/Langs/LangList'
import Modal from '../../controls/Modal/Modal'
import EditLang from '../../components/Langs/EditLang'
import AddLang from '../../components/Langs/AddLang'
import { useNavigate } from "react-router-dom";

function Langs() {

  const [langsState, setlangsState] = useState([])
  const [showModal, setShowModal] = useState(false);
  const [childModal, setchildModal] = useState(null);
  const [titleModal, settitleModal] = useState("");
  const [selectedLang, setSelectedLang] = useState(null);
  const [selectedAction, setSelectedAction] = useState(null);
  const navigate = useNavigate();


  useEffect(() => {

    (new API()).sendGETWithToken("/api/langs", (result) => {
      setlangsState(result);
    })

  }, [])

  const AddLangClicked = (event) => {
    event.preventDefault();
    setSelectedLang(null)
    settitleModal("افزودن محصول")
    setSelectedLang({})
    setSelectedAction("add")
    setchildModal(<AddLang item={null} changed={onSetLangUpdate} />)
    setShowModal(true)
  }
  const onSaveModal = (event) => {
    event.preventDefault();

    switch (selectedAction) {
      case "edit":
        if (selectedLang) {
          (new API()).sendPatchWithToken(`/api/langs/${selectedLang.id}`, selectedLang, (result) => {
            console.log(result);
            setSelectedAction(null)
            setSelectedLang(null)
            setShowModal(false)
            console.clear()
            setlangsState(result);

          })
        }
        break;
      case "add":
        if (selectedLang) {
          (new API()).sendPostWithToken(`/api/langs`, selectedLang, (result) => {
            console.log(result);
            setSelectedAction(null)
            setSelectedLang(null)
            setShowModal(false)
            console.clear()
            setlangsState(result);

          })
        }
        break;

      default:
        break;
    }




  }

  const closeLangClicked = (event) => {
    event.preventDefault();
    setSelectedLang(null)
    setShowModal(false)
  }
  const onSetLangUpdate = (item) => {
    //event.preventDefault();
    console.log("VVV", item)
    setSelectedLang(item)
  }
  const LangItemClicked = (event, item, action) => {
    event.preventDefault();
    setSelectedLang(null)
    switch (action) {
      case "edit":
        settitleModal("ویرایش زبان")

        setSelectedLang(item)
        setSelectedAction(action)
        setchildModal(<EditLang item={item} changed={onSetLangUpdate} />)
        setShowModal(true)
        break;
      case "words":
        navigate(`/words/${item.id}`)
        break;

      default:
        break;
    }
  }

  return (
    <PageWrapper>
      <PageTitle title="زبانها" />
      <Row>
        <Col size="lg-12">

          <PageCard>
            <div className="btn btn-list mb-2">
              <button className="btn ripple btn-primary" onClick={AddLangClicked}>
                <i className="fe fe-plus-circle mx-1 mt-3"></i>
                زبان جدید </button>
            </div>
            <Table>
              <THead>
                <th className='w-25'>#</th>
                <th>کد</th>
                <th>نام</th>
                <th>عنوان</th>
                <th>جهت</th>

              </THead>
              <tbody>
                {

                  langsState.map((item) => {
                    return <LangList item={item} click={LangItemClicked} />

                  })

                }
              </tbody>
            </Table>
            <Modal isShow={showModal} close={closeLangClicked} title={titleModal} saveClick={(event) => onSaveModal(event)}>
              {childModal}
            </Modal>
          </PageCard>
        </Col>
      </Row>

    </PageWrapper>

  )
}

export default Langs