import React from 'react'

function THead(props) {
  return (
    <thead>
    <tr>
    {props.children}
    </tr>
    </thead>
  )
}

export default THead