import React from 'react'

function PageWrapper(props) {
    return (

        <div className="main-content side-content pt-0 mt-5">
            <div className="container-fluid">
                <div className="inner-body">
                    {props.children}
                </div>
            </div>
        </div>
    )
}

export default PageWrapper