import React from 'react'
import Utils from '../../modules/utils'
import { Directions } from '../../store/consts';

function LangsList({ item,click }) {
  return (
    <tr key={item.id}>
      <td>

        <div class="btn-icon-list">
          <button class="btn ripple btn-info btn-icon" onClick={(event)=>click(event,item,"words")}><i class="fe fe-list"></i></button>
          <button class="btn ripple btn-primary btn-icon" onClick={(event)=>click(event,item,"edit")}><i class="fe fe-edit"></i></button>
          <button class="btn ripple btn-secondary btn-icon" onClick={(event)=>click(event,item,"delete")}><i class="fe fe-trash"></i></button>
         
        </div>

      </td>
      <td>{item.id}</td>
      <td>{item.name}</td>
      <td>{item.title}</td>
     
      <td>{Directions.filter((el)=>el.value==item.dir)[0].label}</td>

    </tr>

  )
}

export default LangsList